import axios from 'axios';
import _ from 'lodash';

const moduleNotifications = {
  namespaced: true,
  state: {
    notifications: [],
  },
  getters: {
    notifications: (state) => state.notifications,
    drawerNotifications: (state) =>
      state.notifications.filter(
        (item) =>
          item.appName === 'qplus' && item.displayMethod !== 'qplus_banner'
      ),
    bannerNotifications: (state) =>
      state.notifications.filter(
        (item) => {
          const REMOVE_IN_HOURS = 24*14; // The time in hour to remove the banner
          const now = new Date();
          const recivedDatetime = new Date(item.receivedDatetime);
          const timeDifference = now.getTime() - recivedDatetime.getTime();
          const hoursDifference = timeDifference / (1000 * 60 * 60);

          return item.appName === 'qplus' && item.displayMethod === 'qplus_banner' && hoursDifference < REMOVE_IN_HOURS;
        }
      ),
    bannersToBeDeleted: (state) =>
      state.notifications.filter(
        (item) => {
          const REMOVE_IN_HOURS = 24*14; // The time in hour to remove the banner
          const now = new Date();
          const recivedDatetime = new Date(item.receivedDatetime);
          const timeDifference = now.getTime() - recivedDatetime.getTime();
          const hoursDifference = timeDifference / (1000 * 60 * 60);
      
          return item.appName === 'qplus' && item.displayMethod === 'qplus_banner' && hoursDifference >= REMOVE_IN_HOURS;
        }
      ),
  },
  mutations: {
    setNotifications(state, notifications) {
      state.notifications = notifications;
    },
  },
  actions: {
    fetchNotifications({ commit }) {
      let notifications = [];
      axios
        .get(process.env.VUE_APP_NOTIFICATIONS_API, {
          headers: {
            Authorization: 'Bearer ' + this._vm.$OAuth.data.accessToken,
            'id-token': this._vm.$OAuth.data.idToken,
            aad: true,
          },
        })
        .then((response) => {
          if (response && response.data.length > 0) {
            notifications = response.data;
          }
          return commit('setNotifications', notifications);
        })
        .catch(() => {});
    },
    deleteNotification({ commit, state }, requestId) {
      axios
        .delete(process.env.VUE_APP_NOTIFICATIONS_API, {
          headers: {
            Authorization: 'Bearer ' + this._vm.$OAuth.data.accessToken,
            'id-token': this._vm.$OAuth.data.idToken,
            aad: true,
          },
          data: { requestId },
        })
        .then(() => {
          commit(
            'setNotifications',
            _.remove(state.notifications, (val) => {
              return val.requestId !== requestId;
            })
          );
        })
        .catch(() => {});
    },
  },
};
export default moduleNotifications;
