<template>
  <div v-if="quicklinks">
    <div class="mb-5 d-flex align-center">
      <v-icon
        aria-hidden="true"
        size="24"
        color="secondary_grey"
        left
      >
        link
      </v-icon>
      <div class="title">
        {{ quicklinks.title }}
      </div>
    </div>

    <!-- List item -->
    <ul class="pl-0">
      <li
        class="py-1 d-flex mb-2"
        v-for="(link, index) in quicklinks.items"
        :key="index"
      >
        <v-icon
          aria-hidden="true"
          color="white"
          left
        >
          mdi-open-in-new
        </v-icon>
        <a
          class="white--text"
          :href="link.url"
          target="_blank"
        >
          {{ link.text }}
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
  import { mapState } from 'vuex';

  export default {
      data() {
          return {
              quickLink: undefined,
          }
      },
      computed: {
        ...mapState('moduleStaticContent', {
          quicklinks: (state) => state.content.quick_links,
        }),
      },
      created() {
        this.$store.dispatch('moduleStaticContent/fetchStaticContent', 'quick_links')
      }
  }
</script>
