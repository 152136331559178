<template>
  <div class="light10 fill-height">
    <Banner
      icon="apps"
      contentClass="ml-2"
      heading="Resources"
      subtitle="Essential resources for my work"
      backgroundImageUrl="images/page-banner.png"
    />

    <v-container class="container-main py-6" fluid>
      <!-- Search Input -->
      <AdsTextField
        solo
        clearable
        label="Search for any resource"
        prepend-inner-icon="mdi-magnify grey--text"
        v-model="searchBarValue"
      />
      <!-- End Search Input -->

      <div class="d-flex align-center" role="search">
        <div class="mr-2 d-none d-md-block" id="filter-label">Filter by:</div>

        <div
          class="d-flex flex-wrap"
          aria-labelledby="filter-label"
          role="group"
        >
          <ChipFilter
            v-for="(filter, iFilter) in filters"
            :key="iFilter"
            :name="filter.name"
            :items="filter.options"
            v-model="filter.value"
            type="checkbox"
            class="mx-1"
            color="primary"
            role="option"
          />
        </div>
      </div>

      <!-- Pinned Resource - Top Section -->
      <div v-if="pinnedResources.length" class="my-2 py-6 px-3 px-md-0">
        <h2 class="mb-3 mb-md-6 primary--text">Q+ Pinned Resources</h2>
        <v-slide-group
          show-arrows
          active-class="primary"
          prev-icon="mdi-chevron-left-circle primary--text white rounded-circle"
          next-icon="mdi-chevron-right-circle primary--text white rounded-circle"
          v-model="selectedSlide"
          center-active
        >
          <v-slide-item
            v-for="(resource, iResource) in pinnedResources"
            :key="iResource"
            v-slot="{ active, toggle }"
          >
            <ResourceCard
              class="ma-4"
              :resource="resource"
              :iResource="iResource"
              :thumbnails="thumbnails"
            />
          </v-slide-item>
        </v-slide-group>
      </div>
      <!-- End of Pinned Resource  -->

      <!-- Other Resources - Bottom Section -->
      <div v-if="otherResources.length" class="my-2 py-4 py-md-6 px-3 px-md-0">
        <h2 class="mb-3 mb-md-6 primary--text">Other Resources</h2>

        <div id="otherResources" class="d-flex flex-wrap justify-space-between">
          <ResourceCard
            v-for="(resource, iResource) in otherResources"
            class="ma-4"
            :key="iResource"
            :resource="resource"
            :iResource="iResource"
            :resourceLength="otherResources.length"
            :thumbnails="thumbnails"
          />
        </div>
      </div>
      <!-- End Other Resources -->
    </v-container>
  </div>
</template>

<script>
import { Banner, ChipFilter, AdsTextField } from '@nswdoe/doe-ui-core';
import { mapState } from 'vuex';
import ResourceCard from './ResourceCard';

export default {
  title: 'Resources - Q+',
  components: {
    Banner,
    ChipFilter,
    AdsTextField,
    ResourceCard,
  },
  data() {
    return {
      searchBarValue: '',
      filters: [
        {
          name: 'Cycle',
          searchValue: 'cycles',
          value: [],
          options: [],
        },
        {
          name: 'Role',
          searchValue: 'roles',
          value: [],
          options: [],
        },
        {
          name: 'Type',
          searchValue: 'types',
          value: [],
          options: [],
        },
        {
          name: 'Step',
          searchValue: 'step',
          value: [],
          options: [],
        },
      ],
      selectedSlide: null,
      thumbnails: {},
    };
  },
  computed: {
    pinnedResources() {
      return this.filteredResources?.filter(
        (resource) => resource.pinned === true
      );
    },
    otherResources() {
      return this.filteredResources?.filter(
        (resource) => resource.pinned !== true
      );
    },
    ...mapState('moduleStaticContent', {
      resources: (state) => state.content.resources || [],
    }),
    filteredResources() {
      return (
        this.resources?.data?.filter((resource) => {
          const searchBarValue = this.searchBarValue?.toLowerCase() || '';
          const isIncludesSearchBar =
            resource.title.toLowerCase().includes(searchBarValue) ||
            resource.description.toLowerCase().includes(searchBarValue);

          return (
            isIncludesSearchBar &&
            this.filters
              .map((filter) => {
                const searchValue = resource[filter.searchValue];
                const isIncludes = Array.isArray(searchValue)
                  ? searchValue.some((value) => filter.value.includes(value))
                  : filter.value.includes(searchValue);

                return filter.value.length === 0 || isIncludes;
              })
              .every((status) => status === true)
          );
        }) || []
      );
    },
  },
  watch: {
    resources: {
      immediate: true,
      handler(resources) {
        if (resources && resources.data) {
          if (resources.filters) {
            // get filters options & get thumbnail
            this.filters = resources.filters.map((filter) => {
              const setOptions = new Set([]);

              resources?.data.forEach((resource) => {
                const optionValue = resource[filter.value];

                if (Array.isArray(optionValue)) {
                  optionValue.forEach((option) => setOptions.add(option));
                } else {
                  setOptions.add(optionValue);
                }
              });

              const options = Array.from(setOptions).map((option) => {
                return {
                  id: option,
                  name: option,
                };
              });

              return {
                name: filter.name,
                searchValue: filter.value,
                value: [],
                options,
              };
            });
          }

          resources.data.forEach((resource) => {
            // add thumbnail to cache
            if (resource.thumbnail_file_name) {
              this.$set(this.thumbnails, resource.thumbnail_file_name, '');
            }
            if (resource.cycles[0]) {
              this.$set(
                this.thumbnails,
                `${resource.cycles[0].replaceAll(' ', '_')}.svg`,
                ''
              );
            }

            if (resource.cycles[1]) {
              this.$set(
                this.thumbnails,
                `${resource.cycles[1].replaceAll(' ', '_')}.svg`,
                ''
              );
            }
          });
          Object.keys(this.thumbnails).forEach((thumbnailName) => {
            this.generateLinkThumbnail(thumbnailName);
          });
        }
      },
    },
  },
  created() {
    this.$store.commit('SET_IS_LOADING', true);
    this.$store
      .dispatch('moduleStaticContent/fetchStaticContent', 'resources')
      .finally(() => {
        this.$store.commit('SET_IS_LOADING', false);
      });
  },
  methods: {
    async generateLinkThumbnail(fileName) {
      await this.$http
        .get('/api/static/resources/thumbnail/' + fileName, {
          responseType: 'blob',
        })
        .then((response) => {
          this.$set(
            this.thumbnails,
            fileName,
            URL.createObjectURL(response.data)
          );
        });
    },
    async generateCycleThumbnail(cycle) {
      await this.$http
        .get('/api/static/resources/thumbnail/' + cycle, {
          responseType: 'blob',
        })
        .then((response) => {
          this.$set(this.thumbnails, cycle, URL.createObjectURL(response.data));
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.v-slide-group__prev {
  position: absolute;
  top: 40%;
  left: -1.6rem;
  z-index: 2;
  i {
    font-size: 40px !important;
  }
}
.v-slide-group__next {
  position: absolute;
  top: 40%;
  right: -1.6rem;
  font-size: 40px;
  i {
    font-size: 40px !important;
  }
}

.v-card .v-card__actions ul {
  padding-right: 12px !important;
}

.v-image svg {
  width: 100%;
  height: 100%;
}
</style>
