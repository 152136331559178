import { render, staticRenderFns } from "./MandatoryTrainingReminders.vue?vue&type=template&id=638b06ec&scoped=true"
import script from "./MandatoryTrainingReminders.vue?vue&type=script&lang=js"
export * from "./MandatoryTrainingReminders.vue?vue&type=script&lang=js"
import style0 from "./MandatoryTrainingReminders.vue?vue&type=style&index=0&id=638b06ec&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "638b06ec",
  null
  
)

export default component.exports