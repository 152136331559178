import Vue from 'vue';
import Vuex from 'vuex';

import moduleEssentials from './modules/essentials';
import moduleDocuments from './modules/documents';
import moduleUserProfile from './modules/userProfile';
import moduleTasks from './modules/tasks';
import moduleStaticContent from './modules/staticContent'
import moduleNotifications from './modules/notifications'

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    moduleEssentials,
    moduleDocuments,
    moduleUserProfile,
    moduleTasks,
    moduleStaticContent,
    moduleNotifications,
  },
  state: {
    isLoading: false,
    hasError: false,
  },
  mutations: {
    SET_IS_LOADING(state, isLoading) {
      state.isLoading = isLoading;
    },
    SET_HAS_ERROR(state, hasError) {
      state.hasError = hasError;
    },
  },
})
