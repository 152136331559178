<template>
  <v-card v-if="currentChecklist">
    <v-card-title>
      <div class="d-flex align-center">
        <svg
          class="mr-3"
          width="21"
          height="20"
          viewBox="0 0 21 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.4167 19.75L12.4375 16.5L13.6942 15.2433L15.4167 16.9658L19.3058 13.0767L20.5625 14.6042L15.4167 19.75ZM10.8667 19.75H2.41667C1.21417 19.75 0.25 18.7858 0.25 17.5833V2.41667C0.25 1.21417 1.21417 0.25 2.41667 0.25H17.5833C18.7858 0.25 19.75 1.21417 19.75 2.41667V10.8667C19.0892 10.4875 18.3633 10.2167 17.5833 10.0867V2.41667H2.41667V17.5833H10.0867C10.2167 18.3633 10.4875 19.0892 10.8667 19.75ZM10 15.4167H4.58333V13.25H10V15.4167ZM12.9033 11.0833H4.58333V8.91667H15.4167V10.0867C14.4958 10.2383 13.6508 10.585 12.9033 11.0833ZM15.4167 6.75H4.58333V4.58333H15.4167"
            fill="#002664"
          />
        </svg>

        <h2 class="title font-weight-bold">
          {{ currentChecklist.title }}
        </h2>
      </div>
    </v-card-title>
    <hr>
    <v-card-text
      class="text-body-1 dark--text"
      v-show="!isReadOnly && currentChecklist.description"
    >
      <span v-html="currentChecklist.description" />
    </v-card-text>

    <div
      class="pb-2"
      v-if="currentChecklist.phases && currentChecklist.phases.length"
    >
      <v-divider />

      <div
        class="my-4"
        v-for="(phase, iPhase) in currentChecklist.phases"
        :key="iPhase"
      >
        <v-card-title>
          <h3 class="title font-weight-bold text-uppercase">
            {{ phase.title }}
          </h3>
        </v-card-title>
        <v-card-text
          v-show="!isReadOnly && phase.description"
          class="text-body-1 dark--text"
        >
          <div v-html="phase.description" />
        </v-card-text>
        <v-data-table
          :items="phase.steps"
          :headers="headers"
          :show-expand="!isReadOnly"
          :mobile-breakpoint="0"
          item-key="title"
          :expanded.sync="expanded[`${indexCurrentChecklist} - ${iPhase}`]"
          :hide-default-header="isReadOnly || $vuetify.breakpoint.xs"
          hide-default-footer
          single-expand
          :class="{ 'datatable--is-mobile': $vuetify.breakpoint.mobile }"
        >
          <template v-slot:item.title="{ item, index }">
            <a
              v-if="isReadOnly"
              href="#"
              @click.prevent="linkToTaskPage(index, iPhase)"
            >{{ item.title }}</a>
            <div v-else>
              {{ item.title }}
            </div>
          </template>

          <template v-slot:item.checkbox="{ item }">
            <v-checkbox
              aria-label="Task complete?"
              class="pt-0"
              v-if="!isReadOnly"
              color="success"
              @change="toggleCompletedTask(item.id)"
              :input-value="completedTasks.includes(item.id)"
            />
            <div v-else>
              <v-icon
                aria-label="Task not done"
                color="#DADADA"
              >
                mdi-check-circle-outline
              </v-icon>
            </div>
          </template>
          <template v-slot:item.data-table-expand="{ item, isExpanded }">
            <v-icon
              size="30"
              :aria-expanded="isExpanded ? 'true' : 'false'"
              :aria-controls="'details' + iPhase + phase.steps.indexOf(item)"
              :aria-label="isExpanded ? 'Hide details ' : 'Show details'"
              @click="
                handleExpansion(
                  item,
                  isExpanded,
                  iPhase,
                  phase.steps.indexOf(item)
                )
              "
            >
              {{ isExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
            </v-icon>
          </template>
          <template v-slot:expanded-item="{ item }">
            <td
              :id="'details' + iPhase + phase.steps.indexOf(item)"
              colspan="4"
              class="pt-4 pb-6"
            >
              <div class="mb-4">
                <Chip
                  v-if="item.process"
                  color="secondary"
                  class="mr-3 mb-1 primary--text"
                >
                  <div class="d-flex align-center">
                    <v-icon
                      aria-hidden="true"
                      left
                    >
                      mdi-book-check-outline
                    </v-icon>
                    <div>
                      {{ item.process }}
                    </div>
                  </div>
                </Chip>
                <Chip
                  v-if="item.cycle"
                  dark
                  color="secondary"
                  class="mr-3 mb-1 primary--text"
                >
                  <div class="d-flex align-center">
                    <v-icon
                      aria-hidden="true"
                      left
                    >
                      mdi-av-timer
                    </v-icon>
                    <div>
                      {{ item.cycle }}
                    </div>
                  </div>
                </Chip>
              </div>
              <div
                class="mb-4"
                v-html="item.description"
              />
              <div v-show="item.actions && item.actions.length > 0">
                <ul
                  v-for="(action, iAction) in item.actions"
                  :key="iAction"
                  class="my-4 pa-3 v-sheet v-card theme--light"
                >
                  <li class="d-flex justify-space-between align-center">
                    <div v-html="action.description" />
                    <div class="d-flex align-center">
                      <div
                        class="ml-10 pl-xs-4 mx-sm-1 text-start"
                        style="width: 124px"
                      >
                        {{ action.authority }}
                      </div>
                      <AdsButton
                        :href="action.url"
                        target="_blank"
                        class="ml-auto"
                        button-text="Go"
                        large
                        icon="mdi-open-in-new"
                      />
                    </div>
                  </li>
                </ul>
              </div>

              <div v-show="item.resources && item.resources.length > 0">
                <div
                  class="font-weight-bold primary--text text-uppercase mb-2 mt-6"
                >
                  Resources
                </div>
                <ul
                  v-for="(resource, iResource) in item.resources"
                  :key="iResource"
                  class="pl-0"
                >
                  <li class="d-flex mb-2">
                    <v-icon
                      aria-hidden="true"
                      class="mr-2"
                    >
                      {{
                        isFileDownload(resource.url)
                          ? 'mdi-download'
                          : 'mdi-open-in-new'
                      }}
                    </v-icon>
                    <a
                      v-if="!isFileDownload(resource.url)"
                      target="_blank"
                      :href="resource.url"
                    >
                      {{ resource.text }}
                    </a>
                    <a
                      v-else
                      href="#"
                      @click.prevent="downloadResource(resource.url)"
                    >
                      {{ resource.text }}
                    </a>
                  </li>
                </ul>
              </div>
            </td>
          </template>
          <template slot="no-data">
            <div />
          </template>
        </v-data-table>
        <v-divider />
      </div>
      <v-container
        v-if="currentChecklist.footer && !isReadOnly"
        class="px-4 pt-4 pb-10"
      >
        <div class="light10 pa-6">
          <h3 class="text-h6 font-weight-bold primary--text mb-2">
            {{ currentChecklist.footer.title }}
          </h3>
          <div v-html="currentChecklist.footer.description" />
        </div>
      </v-container>
    </div>
  </v-card>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { AdsButton, Chip } from '@nswdoe/doe-ui-core';
import _ from 'lodash';

export default {
  props: {
    indexCurrentChecklist: {
      type: Number,
      required: true,
    },
    // isReadOnly: {
    //   type: Boolean,
    // },
  },
  components: {
    AdsButton,
    Chip,
  },
  data() {
    return {
      expanded: {},
    };
  },
  computed: {
    ...mapState('moduleStaticContent', {
      checklists: (state) => state.content.tasks,
    }),
    ...mapGetters({
      isMaintainingAccreditation:
        'moduleUserProfile/isMaintainingAccreditation',
    }),
    ...mapState('moduleTasks', {
      completedTasks: (state) => state.completedTasks || [],
    }),
    isReadOnly() {
      const currentCheckListName =
        this.checklists[this.indexCurrentChecklist].name;
      if (
        this.isMaintainingAccreditation &&
        currentCheckListName === 'Maintaining Proficient Teacher'
      ) {
        return false;
      } else if (
        !this.isMaintainingAccreditation &&
        currentCheckListName === 'Achieving Proficient Teacher'
      ) {
        return false;
      } else return true;
    },
    currentChecklist() {
      if (this.checklists) {
        const checklist = this.checklists[this.indexCurrentChecklist];
        if (checklist.phases) {
          checklist.phases.forEach((phase) => {
            // Add item:checkbox column for data table
            if (phase.steps) {
              phase.steps.forEach((step) => {
                step.checkbox = '';
              });
            }
          });
        }
        return checklist;
      } else {
        return null;
      }
    },
    headers() {
      if (!this.isReadOnly) {
        return [
          { text: 'Step', value: 'title', sortable: false },
          {
            text: 'Authority',
            value: 'authority',
            align: 'start',
            sortable: false,
            width: '134px',
          },
          {
            text: 'Done',
            value: 'checkbox',
            align: 'end',
            sortable: false,
            width: '1px',
          },
          { value: 'data-table-expand' },
        ];
      } else {
        return [
          { text: 'Step', value: 'title', sortable: false },
          {
            text: 'Done',
            value: 'checkbox',
            align: 'end',
            sortable: false,
            width: '1px',
          },
        ];
      }
    },
  },
  created() {
    if (!_.isEmpty(this.$route.params)) {
      this.expanded[
        `${this.$route.params.tabIndex} - ${this.$route.params.phaseIndex}`
      ] = [
        this.checklists[this.$route.params.tabIndex].phases[
          this.$route.params.phaseIndex
        ].steps[this.$route.params.rowIndex],
      ];
      window.scrollTo(0, 0);
      setTimeout(() => {
        document
          .getElementsByClassName('v-data-table__expanded')[0]
          .scrollIntoView({ behavior: 'smooth', block: 'center' });
      }, 250);
    }
  },
  methods: {
    toggleCompletedTask(id) {
      this.$store.dispatch('moduleTasks/toggleCompletedTask', id);
    },
    isFileDownload(url) {
      return url && !url.startsWith('http') && !url.startsWith('/');
    },
    downloadResource(url) {
      this.$store.commit('SET_IS_LOADING', true);

      this.$http
        .get('/api/static/resources/data/' + url, { responseType: 'blob' })
        .then((response) => {
          const href = URL.createObjectURL(response.data);
          // create "a" HTLM element with href to file & click
          const link = document.createElement('a');

          link.href = href;
          link.setAttribute('download', url);
          document.body.appendChild(link);
          link.click();

          // clean up "a" element & remove ObjectURL
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
        })
        .catch(() => {
          this.$store.commit('SET_HAS_ERROR', true);
        })
        .finally(() => {
          if (this.$store.state.isLoading) {
            this.$store.commit('SET_IS_LOADING', false);
          }
        });
    },
    linkToTaskPage(rowIndex, phaseIndex) {
      if (this.isReadOnly) {
        this.$router.push({
          path: `/tasks/${this.indexCurrentChecklist}/${phaseIndex}/${rowIndex}`,
        });
      }
    },
    handleExpansion(item, state, phaseIndex, rowIndex) {
      this.expanded = {};
      state
        ? (this.expanded = {})
        : (this.expanded[`${this.indexCurrentChecklist} - ${phaseIndex}`] = [
            this.checklists[this.indexCurrentChecklist].phases[phaseIndex]
              .steps[rowIndex],
          ]);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
    font-size: 16px !important;
  }

  .v-data-table:not(.datatable--is-mobile)
    > .v-data-table__wrapper
    tbody
    tr.v-data-table__expanded.v-data-table__expanded__row
    > td {
    font-weight: bold !important;
    background: #f4f4f7;
    font-size: 20px !important;
  }

  .v-data-table.datatable--is-mobile
    > .v-data-table__wrapper
    tbody
    tr.v-data-table__expanded.v-data-table__expanded__row
    > td {
    padding-top: 5px;
    background: #f4f4f7;
    &:first-child {
      font-weight: bold !important;
      font-size: 15px !important;
    }
  }

  .v-data-table
    > .v-data-table__wrapper
    tbody
    tr.v-data-table__expanded.v-data-table__expanded__content
    > td {
    font-size: 16px !important;
  }

  .v-data-table__expanded > td,
  .v-data-table__expanded__row > td {
    border-bottom: none !important;
  }
  .v-data-table
    > .v-data-table__wrapper
    tbody
    tr.v-data-table__expanded__content {
    box-shadow: none;
    background: $ads-light-10;
  }

  .v-input--checkbox > .v-input__control > .v-input__slot {
    margin-bottom: 0;
  }
}
</style>
