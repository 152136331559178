<template>
  <div>
    <Dialog
      v-if="resource.type === 'Video'"
      maxWidth="1200"
      :title="selectedSource ? selectedSource.title : ''"
      :openDialog="!!selectedSource"
      :displayCloseBtn="true"
      @close="clearSelectedVideo"
    >
      <template #title>
        <div class="px-6 py-4 primary--text">
          <h2 class="title font-weight-bold">
            {{ selectedSource?.title }}
          </h2>
          <p v-if="selectedSource?.description" class="body-1">
            {{ selectedSource?.description }}
          </p>
        </div>
      </template>

      <template #text>
        <div class="mx-auto">
          <BrightcovePlayer
            :videoId="parseInt(selectedSource?.brightcove_video_id)"
          />
        </div>
      </template>
    </Dialog>
    <!-- Card to display Video Resource -->
    <v-card
      v-if="resource.type === 'Video'"
      :width="$vuetify.breakpoint.mdAndUp ? 528 : 356"
      :height="205"
      @click="selectedSource = resource"
      class="d-flex"
    >
      <div class="ma-4 d-flex flex-column justify-space-between">
        <img
          :src="thumbnails[generateThumbnailName(resource, 0)]"
          height="64px"
          width="64px"
          class="rounded-circle"
          color="white"
        />
        <img
          v-if="resource.cycles[1]"
          :src="thumbnails[generateThumbnailName(resource, 1)]"
          height="64px"
          width="64px"
        />
      </div>

      <div class="d-flex flex-column justify-space-between flex-grow-1">
        <v-card-title
          class="title font-weight-bold"
          style="line-height: 1.4rem"
        >
          {{ resource.title }}
        </v-card-title>

        <v-card-text class="">
          {{ this.truncateText(resource.description, 170) }}
        </v-card-text>

        <div class="mb-4 d-flex justify-space-between">
          <img
            :src="thumbnails[resource.thumbnail_file_name]"
            height="28px"
            width="28px"
            class="ml-4"
          />
          <v-icon class="mr-4" aria-hidden="true"> mdi-launch </v-icon>
        </div>
      </div>
    </v-card>

    <!-- Card to display any other type of resource -->
    <v-card
      v-else
      :width="$vuetify.breakpoint.mdAndUp ? 528 : 356"
      :height="205"
      :href="resource.url"
      target="_blank"
      @click="openResource"
      class="d-flex"
    >
      <div class="ma-4 d-flex flex-column justify-space-between">
        <img
          :src="thumbnails[generateThumbnailName(resource, 0)]"
          height="64px"
          width="64px"
          class="rounded-circle"
          color="white"
        />
        <img
          v-if="resource.cycles[1]"
          :src="thumbnails[generateThumbnailName(resource, 1)]"
          height="64px"
          width="64px"
        />
      </div>

      <div class="d-flex flex-column justify-space-between flex-grow-1">
        <v-card-title
          class="title font-weight-bold"
          style="line-height: 1.4rem"
        >
          {{ resource.title }}
        </v-card-title>

        <v-card-text>
          {{ this.truncateText(resource.description, 155) }}
        </v-card-text>

        <div class="mb-4 d-flex justify-space-between">
          <img
            :src="thumbnails[resource.thumbnail_file_name]"
            height="28px"
            width="28px"
            class="ml-4"
          />
          <v-icon class="mr-4" aria-hidden="true"> mdi-launch </v-icon>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import { Dialog } from '@nswdoe/doe-ui-core';
import BrightcovePlayer from '@/components/BrightcovePlayer';

export default {
  name: 'ResourceCard',
  components: {
    Dialog,
    BrightcovePlayer,
  },
  data() {
    return {
      selectedSource: null,
    };
  },
  props: {
    resource: {
      type: Object,
      default: () => null,
    },
    iResource: {
      type: Number,
      default: () => null,
    },
    thumbnails: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    truncateText(text, maxLength) {
      if (text.length <= maxLength) {
        return text;
      }
      const slicedText = text.slice(0, maxLength).replace(/\s[^\s]*$/, '');
      return /[.!?]$/.test(slicedText) ? slicedText : slicedText + '...';
    },
    generateThumbnailName(resource, index) {
      return `${resource.cycles[index].replaceAll(' ', '_')}.svg`;
    },
    isFileDownload(url) {
      return url && !url.startsWith('http') && !url.startsWith('/');
    },
    clearSelectedVideo() {
      this.selectedSource = null;
      this.selectedSlide = null;
    },
    openResource(event) {
      const anchor = event.target.closest('a'); // Find closest Anchor (or self)
      const url = anchor.getAttribute('href');
      if (this.isFileDownload(url)) {
        event.preventDefault();
        this.$store.commit('SET_IS_LOADING', true);

        this.$http
          .get('/api/static/resources/data/' + url, { responseType: 'blob' })
          .then((response) => {
            const href = URL.createObjectURL(response.data);
            // create "a" HTML element with href to file & click
            const link = document.createElement('a');

            link.href = href;
            link.setAttribute('download', url);
            document.body.appendChild(link);
            link.click();

            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
          })
          .catch(() => {
            this.$store.commit('SET_HAS_ERROR', true);
          })
          .finally(() => {
            if (this.$store.state.isLoading) {
              this.$store.commit('SET_IS_LOADING', false);
            }
          });
      }
    },
  },
};
</script>
