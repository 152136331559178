<template>
  <div class="d-flex-column pa-3 rounded wrapper">
    <div class="d-flex justify-space-between">
      <h3 class="px-3 py-2 title font-weight-bold">
        Professional Development (PD) Hours
      </h3>
      <v-btn
        icon
        color="white"
        aria-label="More information"
        class="chart-icon d-print-none"
        @click="handleOpenChartInfo"
      >
        <v-icon dark>
          mdi-help-circle-outline
        </v-icon>
      </v-btn>
    </div>

    <v-container>
      <v-card class="d-flex justify-space-around chart-container">
        <!-- Accredited PD RadialBar -->
        <div class="col-6 py-0 px-0 align-center d-flex-column">
          <AdsChart
            type="radialBar"
            width="100%"
            height="268"
            :showTitle="false"
            :chartOptions="accreditedPDHoursChartOptions"
            :adsForcedOptions="false"
            :series="accreditedPDHoursSeries"
          />
          <v-btn
            v-if="dashboardContent"
            outlined
            depressed
            large
            color="white"
            class="d-print-none chart-btn"
            target="_blank"
            :href="dashboardContent?.accreditation_progress_url"
          >
            <v-icon
              aria-hidden="true"
              size="24"
              left
            >
              mdi-open-in-new
            </v-icon>
            Update in eTAMS
          </v-btn>
          <div class="white--text body-2 pt-2">
            * Data Provided by NESA
          </div>
        </div>

        <!-- Elective PD RadialBar -->
        <!--  -->

        <!-- <div class="col-6 py-0 px-0 align-center d-flex-column">
          <AdsChart
            type="radialBar"
            width="100%"
            height="268"
            :showTitle="false"
            :chartOptions="electivePDHoursChartOptions"
            :adsForcedOptions="false"
            :series="electivedPDHoursSeries"
          />
          <v-btn
            v-if="dashboardContent"
            outlined
            depressed
            large
            color="white"
            class="d-print-none chart-btn"
            @click="handleOpenElectiveForm"
          >
            <v-icon aria-hidden="true" size="24" left> mdi-update </v-icon>
            Update
          </v-btn>
        </div> -->
      </v-card>
    </v-container>
  </div>
</template>

<script>
import { AdsChart } from '@nswdoe/doe-ui-core';

export default {
  components: {
    AdsChart,
  },
  data() {
    return {};
  },
  props: {
    dashboardContent: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    accreditedPDHours() {
      return this.$store.getters['moduleUserProfile/accreditedPDHours'];
    },
    // Computed prop associated with Elective PD RadialBar
    // electivePDHours() {
    //   return this.$store.getters['moduleUserProfile/electivePDHours'];
    // },
    accreditedPDHoursSeries() {
      return [([this.accreditedPDHours] / 50) * 100];
    },
    // Computed prop associated with Elective PD RadialBar
    // electivedPDHoursSeries() {
    //   return [([this.electivePDHours] / 50) * 100];
    // },
    accreditedPDHoursChartOptions() {
      return {
        plotOptions: {
          radialBar: {
            hollow: {
              margin: 0,
              offsetX: 0,
              size: '55%',
              background: 'transparent',
            },
            track: {
              background: '#002664',
              dropShadow: {
                enabled: true,
                top: 2,
                left: 0,
                blur: 4,
                opacity: 0.15,
              },
            },
            dataLabels: {
              allowHTML: true,
              name: {
                show: true,
                offsetY: -16,
                color: '#fff',
                fontSize: '36px',
                fontWeight: '700',
              },
              value: {
                color: '#fff',
                fontSize: '16px',
                fontWeight: '400',
                offsetY: 0,
                show: true,

                formatter: () => {
                  return ['Accredited', 'PD Hours*'];
                },
              },
            },
          },
        },
        fill: {
          type: 'full',
          colors: ['#00AA45'],
        },
        stroke: {
          lineCap: 'flat',
        },
        labels: [this.accreditedPDHours],
      };
    },
    // Computed prop associated with Elective PD RadialBar
    // electivePDHoursChartOptions() {
    //   return {
    //     plotOptions: {
    //       radialBar: {
    //         hollow: {
    //           margin: 0,
    //           offsetX: 0,
    //           size: '55%',
    //           background: 'transparent',
    //         },
    //         track: {
    //           background: '#002664',
    //           dropShadow: {
    //             enabled: true,
    //             top: 2,
    //             left: 0,
    //             blur: 4,
    //             opacity: 0.15,
    //           },
    //         },
    //         dataLabels: {
    //           allowHTML: true,
    //           name: {
    //             show: true,
    //             offsetY: -16,
    //             color: '#fff',
    //             fontSize: '36px',
    //             fontWeight: '700',
    //           },
    //           value: {
    //             color: '#fff',
    //             fontSize: '16px',
    //             fontWeight: '400',
    //             offsetY: 0,
    //             show: true,

    //             formatter: () => {
    //               return ['Elective', 'PD Hours'];
    //             },
    //           },
    //         },
    //       },
    //     },
    //     fill: {
    //       type: 'full',
    //       colors: ['#FFFFFF'],
    //     },
    //     stroke: {
    //       lineCap: 'flat',
    //     },
    //     labels: [`${this.electivePDHours}`],
    //   };
    // },
  },
  methods: {
    handleOpenChartInfo() {
      this.$emit('openChartInfo', 'radialBar');
    },
    handleOpenElectiveForm() {
      this.$emit('openElectiveForm');
    },
  },
};
</script>

<style lang="scss">
.wrapper {
  height: 100%;
  background-color: #22448b;
}
.v-card__text {
  padding-top: 0px;
}
.chart-container {
  margin-top: -10px;
}
.chart-btn {
  margin-top: -25px;
  border: 2px solid $ads-white;
}
.apexcharts-datalabels-group {
  margin-top: 20px;
}
</style>
