<template>
  <div class="d-flex-column pa-3 rounded wrapper">
    <div class="d-flex justify-space-between">
      <h3 class="px-3 py-2 title font-weight-bold">
        Maintenance Period
      </h3>
      <div class="d-flex">
        <v-btn
          icon
          color="white"
          aria-label="More information"
          class="chart-icon d-print-none"
          @click="handleOpenChartInfo()"
        >
          <v-icon dark>
            mdi-help-circle-outline
          </v-icon>
        </v-btn>
      </div>
    </div>

    <v-container class="d-flex align-center flex-grow-1 my-4">
      <v-card class="col-8 d-flex-column">
        <div class="d-flex container-heading pa-3">
          <v-icon
            aria-hidden="true"
            left
            size="24"
            color="white"
          >
            access_alarm
          </v-icon>
          <h3 class="body-2">
            Time Remaining
          </h3>
        </div>
        <!-- Time Breakdown -->
        <div
          v-if="!maintenancePeriodIsExpired"
          class="d-flex-column align-stretch container-content"
        >
          <div
            class="d-flex align-center space-evenly"
            :class="{ isLessThanAYear, isLessThanSixMonth }"
          >
            <div class="d-flex-column align-center">
              <h3 class="display-time font-weight-bold mb-5">
                {{ remainingTimeBreakDown.yearsRemaining }}
              </h3>
              <div class="body-2">
                Years
              </div>
            </div>

            <div class="d-flex-column align-center">
              <h3 class="display-time font-weight-bold mb-5">
                {{ remainingTimeBreakDown.monthsRemaining }}
              </h3>
              <div class="body-2">
                Months
              </div>
            </div>

            <div class="d-flex-column align-center">
              <h3 class="display-time font-weight-bold mb-5">
                {{ remainingTimeBreakDown.weeksRemaining }}
              </h3>
              <div class="body-2">
                Weeks
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="maintenancePeriodIsExpired"
          class="d-flex-column align-stretch container-content"
        >
          <div class="d-flex-column">
            <v-icon
              aria-hidden="true"
              class="warning--text mb-4 warning-icon"
            >
              error
            </v-icon>
            <h3 class="px-2 mb-5 text-center">
              Your Maintenance Period expired
              {{ Math.abs(maintenancePeriodRemainingDays) }} days ago.
            </h3>
          </div>
        </div>
        <!-- ... -->
      </v-card>

      <!-- End Date -->
      <v-card class="col-4 d-flex-column end-date">
        <div class="d-flex container-heading pa-3">
          <v-icon
            aria-hidden="true"
            left
            size="24"
            color="white"
          >
            calendar_today
          </v-icon>

          <h3 class="body-2">
            End Date
          </h3>
        </div>
        <div class="d-flex-column container-content">
          <!-- Day -->
          <h4 class="date-content">
            {{ endOfMaintenancePeriodDay.value }}
          </h4>
          <!-- Month -->
          <h4 class="date-content">
            {{ endOfMaintenancePeriodMonth.value }}
          </h4>
          <!-- Year -->
          <h4 class="date-content font-weight-bold">
            {{ endOfMaintenancePeriodYear.value }}
          </h4>
        </div>
      </v-card>
      <!-- ... -->
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      remainingTimeBreakDown:
        this.$store.getters['moduleUserProfile/remainingTimeBreakdown'],
      endOfMaintenancePeriod:
        this.$store.getters['moduleUserProfile/endOfMaintenancePeriod'],
      maintenancePeriodInDays:
        this.$store.getters['moduleUserProfile/maintenancePeriodInDays'],
      maintenancePeriodElapsedDays:
        this.$store.getters['moduleUserProfile/maintenancePeriodElapsedDays'],
    };
  },

  computed: {
    endOfMaintenancePeriodDay() {
      return this.findDatePart(this.endOfMaintenancePeriod, 'day');
    },
    endOfMaintenancePeriodMonth() {
      return this.findDatePart(this.endOfMaintenancePeriod, 'month');
    },
    endOfMaintenancePeriodYear() {
      return this.findDatePart(this.endOfMaintenancePeriod, 'year');
    },
    maintenancePeriodRemainingDays() {
      return Math.round(
        this.maintenancePeriodInDays - this.maintenancePeriodElapsedDays
      );
    },
    maintenancePeriodIsExpired() {
      return this.maintenancePeriodRemainingDays > 0 ? false : true;
    },
    isLessThanAYear() {
      if (this.findDatePart(this.endOfMaintenancePeriod, 'year') < 1)
        return true;
      else return false;
    },
    isLessThanSixMonth() {
      if (
        this.findDatePart(this.endOfMaintenancePeriod, 'month') <= 6 &&
        this.isLessThanAYear
      )
        return true;
      else return false;
    },
  },

  methods: {
    findDatePart(formattedDate, dayUnit) {
      return formattedDate.find((part) => part.type === `${dayUnit}`);
    },
    handleOpenChartInfo() {
      this.$emit('openChartInfo');
    },
  },
};
</script>

<style lang="scss">
.wrapper {
  height: 100%;
  background-color: #22448b;
}

.d-flex-column {
  display: flex;
  flex-direction: column;
}

.v-card {
  min-height: 100%;

  .container-heading {
    background-color: $ads-secondary-blue;
    align-items: center;
  }

  .container-content {
    background-color: $ads-primary-blue;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
  }

  .display-time {
    font-family: 'Arial';
    font-weight: 700;
    font-size: 46px;
    line-height: 32px;
  }

  .date-content {
    font-family: Arial;
    font-size: 28px;
    font-weight: 400;
    line-height: 32px;
  }

  .space-evenly {
    justify-content: space-evenly;
  }

  .isLessThanAYear {
    color: $ads-warning-orange;
  }

  .isLessThanAYear {
    color: $ads-error-red;
  }
}

.card-heading {
  font-size: 18px;
  line-height: 25px;
}

.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none;
}
</style>
