<template>
  <div class="d-flex flex-column full-height">
    <Banner
      icon="storefront"
      contentClass="ml-2"
      class="display-1"
      heading="Feedback"
      subtitle="Join the conversation and help shape the future of Q+"
      backgroundImageUrl="images/page-banner.png"
    />

    <div>
      <TabNavigation
        v-if="!loading"
        :grow="$vuetify.breakpoint.xlAndDown"
        mobile-breakpoint="xs"
        :items="tabs"
        slider-color="#D7153A"
        slider-size="4"
      >
        <template v-slot:content1>
          <FeedbackLinks />
        </template>
        <!-- Q+ Yammer Feed -->
        <!-- <template v-slot:content1>
          <v-container id="yammer-container">
            <div id="yammer-feed" />
          </v-container>
        </template> -->
        <!-- ...  -->

        <!-- Staffrooms -->
        <template v-slot:content2>
          <StaffroomLinks />
        </template>
        <!-- ... -->
      </TabNavigation>
    </div>
  </div>
</template>

<script>
import StaffroomLinks from './StaffroomLinks';
import { Banner } from '@nswdoe/doe-ui-core';
import { TabNavigation } from '@nswdoe/doe-ui-core';
import FeedbackLinks from '../support-feedback/FeedbackLinks.vue'

export default {
  components: {
    Banner,
    TabNavigation,
    StaffroomLinks,
    FeedbackLinks
  },
  data() {
    return {
      loading: false,
      tabs: [{ tab: 'Feedback' }],
    };
  },

  methods: {
    displayYammer() {
      let script = document.createElement('script');
      script.setAttribute(
        'src',
        'https://s0-azure.assets-yammer.com/assets/platform_embed.js'
      );
      window.document.head.appendChild(script);

      setTimeout(() => {
        if (window.yam === undefined) {
          throw new Error('CommunityPage - window.yam is undefined');
        } else {
          window.yam.connect.embedFeed({
            container: '#yammer-feed',
            network: 'det.nsw.edu.au',
            feedType: 'group',
            feedId: '83205980160',
          });
        }
      }, 2000);
    },
  },
  mounted() {
    this.displayYammer();
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .v-main__wrap {
    display: flex;
  }
  .v-tab {
    text-align: center;
    width: 100%;
    padding: 5px 2px;
  }

  .v-window {
    background-color: $ads-light-10;
  }
}

#yammer-container {
  padding: 0px;
  height: 100vh;

  #yammer-feed {
    height: 100%;
  }
}
</style>
